import { firestore, fieldValue } from "services/firebaseService/firebaseConfig";
// eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";

export interface ConversationBroadcastFirebase {
  readonly sessionId?: string;
  readonly sender?: string;
  readonly sentAt?: {
    seconds: number;
  };
  readonly channel?: string;
}

export const CONVERSATION_BROADCAST_PATH = "ConversationBroadcast";

export const useConversationBroadcastFirebase = () => {
  const getConversationBroadcastDocRef = (channel: string) =>
    firestore.collection(CONVERSATION_BROADCAST_PATH).doc(channel);

  const startConversationBroadcast = (
    channel: string,
    broadcast: ConversationBroadcastFirebase,
  ) => {
    const docRef = getConversationBroadcastDocRef(channel);
    const announcementWithoutUndefinedProps = omitBy(broadcast, isUndefined);

    docRef.set({
      ...announcementWithoutUndefinedProps,
      sentAt: fieldValue.serverTimestamp(),
    });
  };

  const stopConversationBroadcast = (channel: string) => {
    const docRef = getConversationBroadcastDocRef(channel);
    docRef.delete();
  };

  return {
    getConversationBroadcastDocRef,
    startConversationBroadcast,
    stopConversationBroadcast,
  };
};
