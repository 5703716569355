import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Moment } from "moment";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { DateTimePicker } from "@remo-co/ui-core/src/components/DateTimePicker";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { PickerProvider } from "@remo-co/ui-core/src/components/PickerProvider";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { getCurrentTimezone } from "helpers/time/timezone";
import * as FileStoragePaths from "services/firebaseService/storagePaths";
import UploadCaption, {
  BestUploadSizes,
  MaxUploadSizes,
} from "modules/uploader/UploadCaption";
import { ImageUploader } from "modules/uploader";
import { IEvent } from "modules/event/types";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { EVENT_BANNER_MAX_FILE_SIZE } from "../../constants";
import useStyles from "./styles";
import { CustomizeTheme } from "../CustomizeTheme";

interface Props {
  eventData: IEvent;
  isStartTimeDisabled: boolean;
  isGeneratingImage: boolean;
  onEventDataChange: (eventData: Partial<IEvent>) => void;
}

export const BasicSettingsTab = ({
  eventData,
  onEventDataChange,
  isStartTimeDisabled = false,
  isGeneratingImage,
}: Props): JSX.Element => {
  const timezone = getCurrentTimezone();
  const dispatch = useAppDispatch();
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();
  const [logoURL, setLogoURL] = useState(eventData.logoURL);
  const [startTime, setStartTime] = useState(eventData.startTime);
  const [endTime, setEndTime] = useState(eventData.endTime);
  const [provider] = useState(eventData.provider);
  const [eventName, setEventName] = useState({
    error: false,
    value: eventData.name,
    message: "",
  });
  const isPrivate = eventData && eventData.isPrivate;
  const { isUnlimitedEvent } = eventData;

  const updateTextFields = useCallback(() => {
    onEventDataChange({
      name: eventName.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName.value]);

  useEffect(() => {
    const debouncedEventDataUpdate = setTimeout(() => updateTextFields(), 250);

    return () => {
      clearTimeout(debouncedEventDataUpdate);
    };
  }, [updateTextFields]);

  const handleEventName = (e: ChangeEvent<HTMLInputElement>) => {
    setEventName({ error: false, value: e.target.value, message: "" });
  };

  const getAbsTime = (time: Moment) => Math.abs(time.valueOf() / 1000) * 1000;

  const handleStartTime = (time: Moment | null) => {
    if (!time) return;

    const absTime = getAbsTime(time);
    setStartTime(absTime);
  };
  const handleEndTime = (time: Moment | null) => {
    if (!time) return;

    const absTime = getAbsTime(time);
    setEndTime(absTime);
  };

  const handlePrivateEventChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const publicToPrivateMessage = t("eventForm:public.private.message");
    const publicToPrivateConfirmText = t("eventForm:affirm.make.private");
    const publicToPrivateDismissText = t("eventForm:deny.make.private");

    const privateToPublicMessage = t("eventForm:warning.event.public");
    const privateToPublicConfirmText = t("eventForm:affirm.make.public");
    const privateToPublicDismissText = t("eventForm:deny.make.public");

    const message = isPrivate ? privateToPublicMessage : publicToPrivateMessage;
    const confirmText = isPrivate
      ? privateToPublicConfirmText
      : publicToPrivateConfirmText;
    const dismissText = isPrivate
      ? privateToPublicDismissText
      : publicToPrivateDismissText;

    dispatch(
      addDialogNotification({
        message,
        confirmText,
        dismissText,
        hideCloseButton: true,
        onConfirm: () => {
          onEventDataChange({ isPrivate: !isPrivate });
        },
      }),
    );
  };

  useEffect(() => {
    const data = {
      isPrivate,
      startTime,
      endTime,
      name: eventName.value,
      logoURL,
    };
    onEventDataChange(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivate, startTime, endTime, logoURL, eventName.value]);

  useEffect(() => {
    if (eventData.endTime !== endTime) {
      setEndTime(eventData.endTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.endTime]);

  return (
    <Box data-testid="basic-event-info">
      <RadioGroup
        className={styles.eventTypeRadio}
        name="isPrivate"
        value={isPrivate}
        onChange={handlePrivateEventChange}
        row
      >
        <FormControlLabel
          value={false}
          control={<Radio color="primary" />}
          label={t("eventForm:label.public.event")}
        />
        {provider ? (
          <Tooltip
            title={t("eventForm:event.create.tooltip.title") as string}
            placement="bottom"
          >
            <FormControlLabel
              value
              control={<Radio disabled color="primary" />}
              label={t("eventForm:label.private.event")}
            />
          </Tooltip>
        ) : (
          <FormControlLabel
            value
            control={<Radio color="primary" />}
            label={t("eventForm:label.private.event")}
          />
        )}
      </RadioGroup>
      <div className={styles.inputFields}>
        <Container bottom="sm">
          <Input
            size="sm"
            fullWidth
            getRemainingCharsMessage={(key) =>
              t("character.remaining", { key })
            }
            label={t("eventForm:event.title")}
            name="eventName"
            placeholder={t("eventForm:placeholder.event.title")}
            value={eventName.value}
            id="event-title"
            onChange={handleEventName}
            inputProps={{ maxLength: 100, "data-testid": "event-title" }}
          />
        </Container>
        {!isUnlimitedEvent && (
          <>
            <div className={styles.dateTimePickerRow}>
              <PickerProvider>
                <div className={styles.startTime}>
                  <Typography variant="h5">
                    {t("eventForm:start.time")}
                  </Typography>
                  <DateTimePicker
                    id="event-start-time-field"
                    placeholder={t("eventForm:placeholder.start.time")}
                    value={startTime}
                    onChange={handleStartTime}
                    className={styles.dateTimePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      className: styles.dateTimePickerInput,
                    }}
                    DialogProps={{
                      id: "event-start-time-dialog",
                    }}
                    inputVariant="outlined"
                    fullWidth
                    disabled={isStartTimeDisabled}
                    helperText={
                      isStartTimeDisabled
                        ? t("eventForm:condition.start.time")
                        : ""
                    }
                  />
                </div>
                <div className={styles.endTime}>
                  <Typography variant="h5">
                    {t("eventForm:end.time")}
                  </Typography>
                  <DateTimePicker
                    id="event-end-time-field"
                    placeholder={t("eventForm:placeholder.end.time")}
                    value={endTime}
                    onChange={handleEndTime}
                    className={styles.dateTimePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      className: styles.dateTimePickerInput,
                    }}
                    DialogProps={{
                      id: "event-end-time-dialog",
                    }}
                    inputVariant="outlined"
                    fullWidth
                  />
                </div>
              </PickerProvider>
            </div>
            <FormHelperText>
              <Typography color="inherit">
                {t("eventForm:start.end.local.time.zone", {
                  key: `${timezone}`,
                })}
              </Typography>
            </FormHelperText>
          </>
        )}
      </div>
      <div className={styles.eventImageContainer}>
        {eventData.logoURL && (
          <img
            src={eventData.logoURL}
            alt="event logo"
            className={styles.eventImage}
          />
        )}
        <ImageUploader
          name="eventLogo"
          level={FileStoragePaths.UPLOAD_LEVEL.EVENT}
          path={FileStoragePaths.EVENT_LOGO}
          onImageUploaded={(logo) => setLogoURL(logo)}
          maxFileSize={EVENT_BANNER_MAX_FILE_SIZE}
          className={styles.uploader}
          variant="secondary"
          color="dark"
          size="md"
          loading={isGeneratingImage}
          data-testid="event-logo-uploader"
        >
          {eventData.logoURL
            ? t("button.change.image")
            : t("button.upload.image")}
        </ImageUploader>

        <div className={styles.uploadCaption}>
          <UploadCaption
            allowGif
            maxSize={MaxUploadSizes.big}
            bestSize={BestUploadSizes.portrait}
          />
        </div>
      </div>
      <CustomizeTheme />
    </Box>
  );
};
