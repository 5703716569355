import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import appSettingReducer from "modules/appSetting/redux/appSettingSlice";
import announcementReducer from "modules/announcement/redux/announcementSlice";
import spaceReducer from "modules/space/redux/spaceSlice";
import eventReducer from "modules/event/eventSlice";
import dailyReducer from "modules/daily/redux/slice";
import eventMemberReducer from "modules/eventMember/redux/slice";
import { usersInEventReducer } from "modules/event/usersInEvent/reducer";
import userRoomStatusReducer from "modules/room/redux/roomSlice";
import eventActivityStatusReducer from "modules/eventActivityStatus/redux/slice";
import mapNavigationReducer from "modules/mapNavigation/redux/mapNavigationSlice";
import deviceInfoReducer from "modules/deviceInfo";
import authReducer from "modules/auth/redux/authSlice";
import networkReducer from "modules/network/redux/reducer";
import helpChatReducer from "modules/helpChat/store/slice";
import notificationReducer from "modules/notification/redux/notificationSlice";
import dialogNotificationsReducer from "modules/dialogNotification/redux/dialogNotificationSlice";
import companyReducer from "modules/company/redux/slice";
import companySettingsReducer from "modules/companySettings/redux/reducer";
import userProfileCardReducer from "modules/userProfileCard/redux/slice";
import chatReducer from "modules/chat/redux/reducer";
import broadcastReducer from "modules/broadcast/redux/reducer";
import broadcasterReducer from "modules/broadcaster/redux/reducer";
import broadcastQAReducer from "modules/qa/redux/reducer";
import broadcastRaiseHandReducer from "modules/broadcastRaiseHand/redux/broadcastRaiseHandSlice";
import broadcastRecordingReducer from "modules/recording/redux/reducer";
import broadcastWhiteboardReducer from "modules/broadcastWhiteboard/redux/slice";
import streamingReducer from "modules/streaming/redux/streamingSlice";
import presenceReducer from "modules/eventUserPresence/redux/reducer";
import roomWhiteboardReducer from "modules/roomWhiteboard/redux/slice";
import userStatusReducer from "modules/userStatus/redux/reducer";
import userPreferencesReducer from "modules/userPreferences/redux/reducer";
import timerReducer from "modules/timer/redux/slice";
import theaterReducer from "modules/theater/theaterSlice";
import userProfileWindowReducer from "modules/userProfileWindow/redux/userProfileWindowSlice";
import showCamAndMicPopoverReducer from "modules/onboarding/redux/reducer";
import audienceViewReducer from "modules/broadcast/AudienceView/reducer";
import serverTimeReducer from "modules/serverTime/redux/reducer";
import couponReducer from "modules/company/companyCoupon/companyCouponSlice";
import eventLandingReducer from "modules/eventLanding/redux/eventLandingSlice";
import systemCheckReducer from "modules/systemCheck/redux/systemCheckSlice";
import eventDirectoryReducer from "modules/eventDirectory/redux/slice";
import transcriptionSlice from "modules/transcription/redux/transcriptionSlice";
import appVersionReducer from "modules/version/appVersion.slice";
import cameraSettingsReducer from "modules/cameraSettings/redux/reducers";
import shuffleAttendeesReducer from "modules/shuffleAttendees/redux/reducers";
import lobbyReducer from "modules/lobby/redux/slice";
import userProblemReducer from "modules/userProblemReporter/redux/reducer";
import {
  assignedTableNudgeSlice,
  assignedShuffleSlice,
} from "modules/assignTables/redux";
import { enforceTableAssignmentSlice } from "modules/enforceTableAssignment/redux";
import { rightPanelSlice } from "modules/rightPanel/redux";
import sideFeatureMenuReducer from "modules/eventButtons/redux";
import { embedContentSlice } from "modules/eventEmbedContent/redux";
import { eventContentSlice } from "modules/eventContent/redux";
import directTableLinkReducer from "modules/conversation/hooks/useCopyTableLink/slice";
import { emojiSlice } from "modules/emoji/redux";
import audioVideoReducer from "modules/audioVideo/redux/slice";
import { switchCompaniesSlice } from "modules/switchCompanies/redux";
import userForceMoveReducer from "modules/userForceMove/redux/slice";
import liveStreamReducer from "modules/liveStream/redux/slice";
import audioReactionsReducer from "modules/audioReactions/redux/slice";
import userSessionInteractionsSlice from "modules/session/redux/slice";
import registrationSlice from "modules/registration/redux/slice";
import customFloorPlanSlice from "modules/customFloorPlan/redux/slice";
import agencyWhiteLabellingSlice from "modules/settings/redux/slice";
import ticketingSlice from "modules/ticketing/redux/slice";
import conversationBroadcastReducer from "modules/conversationBroadcast/redux/slice";
import transcriptionV2Slice from "modules/transcriptionV2/redux/slice";
import roomsReducer from "./rooms/roomsSlice";

const createRootReducer = (history: History) =>
  combineReducers({
    appVersion: appVersionReducer,
    announcement: announcementReducer,
    appSetting: appSettingReducer,
    assignedTableNudge: assignedTableNudgeSlice,
    assignedShuffle: assignedShuffleSlice,
    audienceView: audienceViewReducer,
    audioVideo: audioVideoReducer,
    auth: authReducer,
    // broadcast modules
    broadcast: broadcastReducer,
    broadcastQA: broadcastQAReducer,
    broadcastRaiseHand: broadcastRaiseHandReducer,
    broadcastRecording: broadcastRecordingReducer,
    broadcastWhiteboard: broadcastWhiteboardReducer,
    broadcaster: broadcasterReducer,
    cameraSettings: cameraSettingsReducer,
    chatV2: chatReducer,
    company: companyReducer,
    companySettings: companySettingsReducer,
    companyCoupon: couponReducer,
    deviceInfo: deviceInfoReducer,
    dialogNotifications: dialogNotificationsReducer,
    embedContent: embedContentSlice,
    enforceTableAssignment: enforceTableAssignmentSlice,
    event: eventReducer,
    eventActivityStatus: eventActivityStatusReducer,
    eventContent: eventContentSlice,
    eventLanding: eventLandingReducer,
    eventDirectories: eventDirectoryReducer,
    helpChatEvent: helpChatReducer,
    lobby: lobbyReducer,
    mapNavigation: mapNavigationReducer,
    network: networkReducer,
    notification: notificationReducer,
    presence: presenceReducer,
    rightPanel: rightPanelSlice,
    daily: dailyReducer,
    // room whiteboard
    roomWhiteboard: roomWhiteboardReducer,
    rooms: roomsReducer,
    router: connectRouter(history),
    serverTime: serverTimeReducer,
    showCamAndMicPopover: showCamAndMicPopoverReducer,
    shuffleAttendees: shuffleAttendeesReducer,
    space: spaceReducer,
    streaming: streamingReducer,
    systemCheck: systemCheckReducer,
    theater: theaterReducer,
    timer: timerReducer,
    transcription: transcriptionSlice,
    userPreferences: userPreferencesReducer,
    userProblem: userProblemReducer,
    // user profile
    userProfileCard: userProfileCardReducer,
    userProfileWindow: userProfileWindowReducer,
    userRoomStatus: userRoomStatusReducer,
    userStatus: userStatusReducer,
    usersInEvent: usersInEventReducer,
    userSessionInteractions: userSessionInteractionsSlice,
    sideFeatureMenu: sideFeatureMenuReducer,
    directTableLink: directTableLinkReducer,
    userForceMove: userForceMoveReducer,
    emoji: emojiSlice,
    liveStream: liveStreamReducer,
    switchCompanies: switchCompaniesSlice,
    audioReaction: audioReactionsReducer,
    eventMember: eventMemberReducer,
    registration: registrationSlice,
    customFloorPlan: customFloorPlanSlice,
    agencyWhiteLabel: agencyWhiteLabellingSlice,
    ticketing: ticketingSlice,
    conversationBroadcast: conversationBroadcastReducer,
    transcriptionV2: transcriptionV2Slice,
  });

export default createRootReducer;
