import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { useCallback, useContext, useRef } from "react";
import { DialogType } from "modules/dialogNotification/constants";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Actions, trackAction } from "modules/monitoring";
import { selectUserId } from "modules/auth/redux/selectors";
import { makeSelectIsUserInTutorial } from "modules/userStatus/redux/selectors";
import { FirebaseDBDataSnapshot, FirebaseDBReference } from "types/firebase";
import logger from "logging/logger";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
} from "modules/audioVideo";
import { setActiveTile, setLayout } from "modules/audioVideo/redux/slice";
import { closeDialogNotificationsByType } from "modules/dialogNotification/redux/dialogNotificationSlice";
import whiteboardFirebase from "../../whiteboard.firebase";
import {
  openRoomWhiteboard,
  closeRoomWhiteboard,
  updateRoomWhiteboardState,
} from "../../redux/slice";
import {
  selectIsRoomWhiteboardOpen,
  selectRoomWhiteboard,
} from "../../redux/selectors";

const WHITEBOARD_NOTIFICATION_PERIOD = 30000;

const useRoomWhiteboardActions = () => {
  const dispatch = useAppDispatch();
  const docRef = useRef<FirebaseDBReference>();
  const { startedBy: localStartedBy } = useSelector(selectRoomWhiteboard);
  const userId = useSelector(selectUserId);
  const isMeInTutorial = useSelector(makeSelectIsUserInTutorial(userId ?? ""));
  const isWhiteboardOpen = useSelector(selectIsRoomWhiteboardOpen);

  const { track } = useContext(TRACKING_CONTEXT);

  const openWhiteboard = useCallback(
    ({
      roomId,
      userId,
      userName,
    }: {
      roomId: string | null;
      userId: string | null;
      userName: string;
    }) => {
      dispatch(openRoomWhiteboard());

      if (!roomId) {
        logger.error("[useRoomWhiteboardActions] roomId missing");
        return;
      }

      // if no one started whiteboard yet
      if (!localStartedBy) {
        whiteboardFirebase.openWhiteboardFirebase(roomId, {
          isWhiteboardOpen: true,
          startedByUser: userName,
          startedBy: userId,
        });
      }

      track(Events.WHITE_BOARD_START);
      trackAction(Actions.WHITEBOARD_OPEN, { roomId, userId, userName });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStartedBy],
  );

  const closeWhiteboard = useCallback(
    ({ roomId }: { roomId: string | null }) => {
      dispatch(closeRoomWhiteboard());
      trackAction(Actions.WHITEBOARD_CLOSE, { roomId });
      if (roomId) {
        whiteboardFirebase.openWhiteboardFirebase(roomId, {
          isWhiteboardOpen: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const subscribe = useCallback(
    ({
      userId,
      roomId,
      showNotification,
    }: {
      userId: string;
      roomId: string;
      showNotification: boolean;
    }) => {
      logger.info(`[subscribeWhiteboard] subscribing,
    roomId: ${roomId},
    userId: ${userId},
    showNotification: ${JSON.stringify(showNotification)}`);
      docRef.current = whiteboardFirebase.getRoomWhiteboardDocRef(roomId);
      docRef.current.off();
      docRef.current.on("value", (snapshot: FirebaseDBDataSnapshot) => {
        const val = snapshot.val();

        if (val) {
          const {
            startedBy,
            startedAt,
            isWhiteboardOpen: whiteboardOpen,
          } = val;
          dispatch(
            updateRoomWhiteboardState({
              startedBy,
              startedAt,
            }),
          );
          if (!whiteboardOpen) return;

          if (userId === startedBy) {
            return;
          }
          // show only during 30sec after start
          if (+startedAt < Date.now() - WHITEBOARD_NOTIFICATION_PERIOD) {
            return;
          }

          dispatch(openRoomWhiteboard());
          dispatch(setLayout(DisplayLayout.Focus));
          dispatch(
            setActiveTile({
              streamId: DisplayItemConfig.whiteboardDisplayId,
              type: DisplayItem.whiteboard,
            }),
          );
        } else {
          dispatch(closeDialogNotificationsByType(DialogType.JOIN_WHITEBOARD));
          closeWhiteboard({ roomId });
          // reset
          dispatch(updateRoomWhiteboardState({}));
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMeInTutorial, isWhiteboardOpen],
  );

  const unsubscribe = useCallback(() => {
    if (docRef.current) {
      docRef.current.off();
    }
  }, []);

  return {
    openWhiteboard,
    closeWhiteboard,
    subscribe,
    unsubscribe,
  };
};

export default useRoomWhiteboardActions;
