import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { getVar } from "config";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { getStorage } from "firebase/storage";
import logger from "../../logging/logger";
import { Actions, trackAction } from "../../modules/monitoring";

const firebaseConfig = {
  apiKey: getVar("REACT_APP_FIREBASE_API_KEY"),
  authDomain: getVar("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  databaseURL: getVar("REACT_APP_FIREBASE_DB_URL"),
  projectId: getVar("REACT_APP_FIREBASE_PROJECT_ID"),
  storageBucket: getVar("REACT_APP_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getVar("REACT_APP_FIREBASE_ID"),
};

// last to be replaced
export const firebaseApp = firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const database = firebase.database();

// modular exports
const firebaseAuth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

firebase.onLog(
  ({ type, message }) => {
    if (
      type === "@firebase/firestore" &&
      message.toLowerCase().includes("connection")
    ) {
      trackAction(Actions.FIRESTORE_CONNECTION_ERROR);
    }
  },
  {
    level: "warn",
  },
);

const signOutFirebase = async () => {
  try {
    logger.info("[FirebaseService][signOutFirebase] Sign out firebase.");
    await signOut(firebaseAuth);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "";
    logger.error(
      `[FirebaseService][signOutFirebase] Fail to sign out firebase. Reason: ${errorMessage}`,
    );
  }
};

const serverValue = firebase.database.ServerValue;
const fieldValue = firebase.firestore.FieldValue;

export const getFirebaseUID = (): string | null =>
  firebaseAuth.currentUser?.uid ?? null;

export const initAuthToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    onAuthStateChanged(firebaseAuth, async (user: User | null) => {
      if (user) {
        try {
          const idToken = await user.getIdToken();
          resolve(idToken);
        } catch (err) {
          if (err instanceof Error) {
            logger.error(
              `[FirebaseService][initAuthToken] Fail to get IdToken. Reason: ${err.message}`,
            );
            reject(err);
          } else {
            logger.error(
              `[FirebaseService][initAuthToken] Fail to get IdToken. Unknown error occurred.`,
            );
            reject(new Error("Unknown error occurred."));
          }
        }
      } else {
        logger.debug(
          "[FirebaseService][initAuthToken] Fail to initAuthToken. Reason: User is logged out",
        );
        reject(new Error("User not found."));
      }
    });
  });

export {
  firebaseConfig,
  firebase,
  firestore,
  database,
  signOutFirebase,
  serverValue,
  fieldValue,
  // modular exports below
  firebaseAuth,
  storage,
};
