import { useCallback, useEffect } from "react";
import useBroadcastWhiteboardActions from "modules/broadcastWhiteboard/hooks/useBroadcastWhiteboardActions";
import useRoomWhiteboardActions from "modules/roomWhiteboard/hooks/useRoomWhiteboardActions";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { ActionButton } from "modules/actionButton";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
} from "modules/audioVideo";
import { selectCurrentRoom } from "store/rooms";
import { selectIsWhiteboardEnabled } from "modules/event/selectors";
import { getUserName } from "modules/userProfile";
import { useAppDispatch } from "store/hooks";
import { selectUser } from "modules/auth/redux/selectors";
import { setActiveTile, setLayout } from "modules/audioVideo/redux/slice";
import { usePresentationLayout } from "modules/audioVideo/hooks/useLayout";
import { selectIsWhiteboardOpen } from "modules/broadcastWhiteboard/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectIsRoomWhiteboardOpen } from "modules/roomWhiteboard/redux/selectors";
import { selectConversationBroadcastSessionId } from "modules/conversationBroadcast/redux/selectors";
import { ReactComponent as WhiteboardSmallIcon } from "./icons/whiteboard-small-icon.inline.svg";
import { ReactComponent as WhiteboardIcon } from "./icons/whiteboard-icon.inline.svg";

interface Props {
  isSmallButton?: boolean;
  darkMode?: boolean;
}

const WhiteboardButton = ({
  isSmallButton,
  darkMode,
}: Props): JSX.Element | null => {
  const isRoomWhiteboardOpen = useSelector(selectIsRoomWhiteboardOpen);
  const {
    openWhiteboard: openRoomWhiteboard,
    closeWhiteboard: closeRoomWhiteboard,
  } = useRoomWhiteboardActions();
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const isBroadcastWhiteboardOpen = useSelector(selectIsWhiteboardOpen);
  const { openWhiteboard, closeWhiteboard: closeBroadcastWhiteboard } =
    useBroadcastWhiteboardActions();
  const user = useSelector(selectUser);
  const room = useSelector(selectCurrentRoom);
  const { t } = useI18n();
  const roomId = room ? room.id : null;
  const isWhiteboardEnabled = useSelector(selectIsWhiteboardEnabled);
  const userName = getUserName(user);
  const theaterId = useSelector(selectCurrentTheaterId);
  const { addActiveTile } = usePresentationLayout();
  const dispatch = useAppDispatch();
  const conversationBroadcastSessionId = useSelector(
    selectConversationBroadcastSessionId,
  );

  const whiteboardOpened = isBroadcasting
    ? isBroadcastWhiteboardOpen
    : isRoomWhiteboardOpen;

  const handleOpenWhiteboard = useCallback(() => {
    if (isBroadcasting && theaterId) {
      addActiveTile({
        theaterId,
        streamId: DisplayItemConfig.whiteboardDisplayId,
      });
      openWhiteboard();
    } else {
      dispatch(setLayout(DisplayLayout.Focus));
      dispatch(
        setActiveTile({
          streamId: DisplayItemConfig.whiteboardDisplayId,
          type: DisplayItem.whiteboard,
        }),
      );
      openRoomWhiteboard({
        userId: user?.id ?? null,
        userName,
        roomId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, userName, roomId, openRoomWhiteboard]);

  const handleCloseWhiteboard = useCallback(() => {
    if (isBroadcasting) {
      closeBroadcastWhiteboard();
    } else {
      closeRoomWhiteboard({ roomId });
    }
  }, [roomId, closeRoomWhiteboard, closeBroadcastWhiteboard, isBroadcasting]);

  const handleClick = useCallback(() => {
    if (whiteboardOpened) {
      handleCloseWhiteboard();
    } else {
      handleOpenWhiteboard();
    }
  }, [whiteboardOpened, handleCloseWhiteboard, handleOpenWhiteboard]);

  useEffect(() => {
    if (!isBroadcasting) {
      // NOTE: this needs to be put behind a better condition.
      // The original intention is that, if in presentation-mode, whiteboard is enabled,
      // transitioning to conversation-mode hides the whiteboard,
      // but this also incorrectly sends the RUM action
      closeBroadcastWhiteboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBroadcasting]);

  if (!isWhiteboardEnabled) {
    return null;
  }

  return (
    <ActionButton
      title={
        whiteboardOpened ? t("turn.whiteboard.off") : t("turn.whiteboard.on")
      }
      onClick={handleClick}
      label={t("button.whiteboard")}
      isActive={whiteboardOpened}
      isPopoverButton={isSmallButton}
      icon={isSmallButton ? <WhiteboardSmallIcon /> : <WhiteboardIcon />}
      darkMode={darkMode}
      disabled={!!conversationBroadcastSessionId}
    />
  );
};

export default WhiteboardButton;
